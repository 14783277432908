import React from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import Img from 'gatsby-image';

function AnimalWellness() {
  const { dog, horse, dolphin } = useStaticQuery(graphql`
    query {
      horse: file(relativePath: { eq: "horse.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 600 ) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      dog: file(relativePath: { eq: "dog.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 600 ) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      dolphin: file(relativePath: { eq: "dolphin.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 600 ) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <div className="px-8 md:px-16 lg:max-w-screen-lg mx-auto">
      <h1 className="my-8 md:my-12 lg:my-16 font-semibold text-lg md:text-2xl lg:text-3xl text-gray-800 tracking-wider text-center">
        Animal Wellness
      </h1>

      <Img
        className="my-6 md:my-12 mx-auto"
        style={{ maxWidth: "600px" }}
        fluid={horse.childImageSharp.fluid}
        alt="Horse wellness"
      />

      <div className="mx-6 md:mx-24 my-12">
        <blockquote className="pl-4 font-serif leading-loose text-justify border-l-4 border-gray-900">
          Animals exist with us and communicate with us in many ways, if we allow ourselves to become still enough to notice&hellip; Communication with them is natural.
        </blockquote>
        <cite className="block mt-4 text-xs font-bold text-right uppercase">
          - Misa Hopkins
        </cite>
      </div>

      <div className="my-12">
        <p className="mt-4">
          In BCST for animals it is their body&apos;s inner wisdom that guides the therapeutic process. This intrinsic intelligence is what sets the priorities and sequencing of what has to happen fo healing. This is called the Inherent Treatment Plan.
        </p>

        <p className="mt-4">
          &quot;One of the greatest strengths of craniosacral biodynamics is it&apos;s orientation to Health. There is an understanding in the field that the underlying health of the (body) is a principle that is with us from the moment of conception, is never lost and always at work. In this understanding, the potential for healing, and the knowledge of what needs to occur, is already enfolded within the conditions and suffering present.&quot;
        </p>

        <p className="mt-2">
          <em>— Becker 1997, 2000</em>
        </p>

        <p className="mt-8">
          Many conditions/symptoms have a relationship to difficulties in nervous system regulation. BCST for animals supports this physiological balance which has far reaching effects when it comes to general wellness and/or healing.
        </p>

        <Img
          className="my-6 md:my-12 mx-auto"
          style={{ maxWidth: "600px" }}
          fluid={dog.childImageSharp.fluid}
          alt="Dog wellness"
        />

        <p className="mt-8">
          Benefits for Animals:
        </p>

        <ul className="list-disc ml-8 mt-4">
          <li>A connection to Health that bolsters their body’s self repair mechanism</li>
          <li>Acknowledgment of their experiences/deeply listened to on all levels</li>
          <li>A reduction in pain</li>
          <li>Fascial Release/Spinal Decompression</li>
          <li>Improved function/movement</li>
          <li>Deep rest/relaxation</li>
          <li>Trauma Resolution</li>
          <li>Coping better in stressful situations</li>
          <li>Increased Confidence</li>
        </ul>

        <p className="mt-8">
          To set up for success I prefer to treat your animal at your home which is a familiar environment that supports a sense of safety. For horses they are welcome to come to my farm for treatment if that works best for timing. Please reach out for an appointment through the <Link to="/appointments#appointment-form" className="no-underline hover:underline text-blue-500 text-sm">request form</Link> or call/text directly at <a href="tel:+17802162609" className="no-underline hover:underline text-blue-500 text-sm">780.216.2609</a>. I&apos;m always willing to be creative to find a time that suits as soon as possible.
        </p>
      </div>

      <div className="mt-12 mb-12 clear-left">
        <h4 className="mt-8 mb-4 md:mt-12 md:mb-4 lg:mt-16 lg:mb-4 font-semibold text-lg md:text-xl lg:text-2xl text-gray-800 tracking-wider">
          A Little About My Animal Background:
        </h4>

        <Img
          className="my-6 md:my-12 mx-auto"
          fluid={dolphin.childImageSharp.fluid}
          style={{ maxWidth: "600px" }}
          alt="Angela with dolphin"
        />

        <p className="mt-4">
          As a child I have always had an affinity for animals and nature, dreaming of being like Fern in Charlotte&apos;s web with the ability to listen to the animals/understand them.
        </p>

        <p className="mt-4">
          I was able to start in the marine animal industry in 1995 as a volunteer at WEM Dolphin Lagoon. Eventually I earned a position on the animal care team and through the years made my way to senior animal trainer and supervisor of animal care. I was part of the marine animal care staff from 1997-2007. During that time I also earned a BSc. in Psychology where behaviour and learning theory supported my animal training/husbandry goals. It was a remarkable place to grow into a young adult, especially to have marine animals as my mentors in all things relational/communication, conscious breathing, intuition, intention and authenticity!
        </p>

        <p className="mt-4">
          In 2008 I shifted to life on a farm with my partner and started working in the dairy industry as a milker and farmhand. For over 2 years the cows continued to teach me about communication, husbandry, intuition, and integrity. Also at that time I was exploring yoga, meditation, and conscious movement/dance. Eventually I trained in facilitating all of the above. These practices have deepened my body awareness, and as such, my perceptual abilities. I have a sensitivity to animal body language and energetic communication.
        </p>

        <p className="mt-4">
          Now the embodiment skills I hold as a biodynamic craniosacral therapist have deepened my sensitivities to a whole other level, and I feel I have come full circle in some ways of how I began — listening to, trusting, and learning from nature.
        </p>
      </div>
    </div>
  );
}

export default AnimalWellness;
